/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after,
  p::first-letter,
  div::first-letter,
  blockquote::first-letter,
  li::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.75;
  color: #292b2c;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 200;
  line-height: 1.25;
  color: inherit; }

h1, .h1 {
  font-size: 3rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.25; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.25; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid #eceeef; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0; }

.blockquote-reverse .blockquote-footer::before {
  content: ""; }

.blockquote-reverse .blockquote-footer::after {
  content: "\00A0 \2014"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #636c72; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f7f7f9;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #292b2c;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #292b2c; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 576px) {
    .container {
      width: 540px;
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      width: 720px;
      max-width: 100%; } }
  @media (min-width: 992px) {
    .container {
      width: 960px;
      max-width: 100%; } }
  @media (min-width: 1200px) {
    .container {
      width: 1140px;
      max-width: 100%; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  @media (min-width: 576px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 768px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 992px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 1200px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-right: 15px;
      padding-left: 15px; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  width: 8.3333333333%; }

.col-2 {
  width: 16.6666666667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.3333333333%; }

.col-5 {
  width: 41.6666666667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.3333333333%; }

.col-8 {
  width: 66.6666666667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.3333333333%; }

.col-11 {
  width: 91.6666666667%; }

.col-12 {
  width: 100%; }

.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.3333333333%; }

.pull-2 {
  right: 16.6666666667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.3333333333%; }

.pull-5 {
  right: 41.6666666667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.3333333333%; }

.pull-8 {
  right: 66.6666666667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.3333333333%; }

.pull-11 {
  right: 91.6666666667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.3333333333%; }

.push-2 {
  left: 16.6666666667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.3333333333%; }

.push-5 {
  left: 41.6666666667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.3333333333%; }

.push-8 {
  left: 66.6666666667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.3333333333%; }

.push-11 {
  left: 91.6666666667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    width: 8.3333333333%; }
  .col-sm-2 {
    width: 16.6666666667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3333333333%; }
  .col-sm-5 {
    width: 41.6666666667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3333333333%; }
  .col-sm-8 {
    width: 66.6666666667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3333333333%; }
  .col-sm-11 {
    width: 91.6666666667%; }
  .col-sm-12 {
    width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.3333333333%; }
  .pull-sm-2 {
    right: 16.6666666667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.3333333333%; }
  .pull-sm-5 {
    right: 41.6666666667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.3333333333%; }
  .pull-sm-8 {
    right: 66.6666666667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.3333333333%; }
  .pull-sm-11 {
    right: 91.6666666667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.3333333333%; }
  .push-sm-2 {
    left: 16.6666666667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.3333333333%; }
  .push-sm-5 {
    left: 41.6666666667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.3333333333%; }
  .push-sm-8 {
    left: 66.6666666667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.3333333333%; }
  .push-sm-11 {
    left: 91.6666666667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    width: 8.3333333333%; }
  .col-md-2 {
    width: 16.6666666667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3333333333%; }
  .col-md-5 {
    width: 41.6666666667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3333333333%; }
  .col-md-8 {
    width: 66.6666666667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3333333333%; }
  .col-md-11 {
    width: 91.6666666667%; }
  .col-md-12 {
    width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.3333333333%; }
  .pull-md-2 {
    right: 16.6666666667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.3333333333%; }
  .pull-md-5 {
    right: 41.6666666667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.3333333333%; }
  .pull-md-8 {
    right: 66.6666666667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.3333333333%; }
  .pull-md-11 {
    right: 91.6666666667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.3333333333%; }
  .push-md-2 {
    left: 16.6666666667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.3333333333%; }
  .push-md-5 {
    left: 41.6666666667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.3333333333%; }
  .push-md-8 {
    left: 66.6666666667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.3333333333%; }
  .push-md-11 {
    left: 91.6666666667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    width: 8.3333333333%; }
  .col-lg-2 {
    width: 16.6666666667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3333333333%; }
  .col-lg-5 {
    width: 41.6666666667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3333333333%; }
  .col-lg-8 {
    width: 66.6666666667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3333333333%; }
  .col-lg-11 {
    width: 91.6666666667%; }
  .col-lg-12 {
    width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.3333333333%; }
  .pull-lg-2 {
    right: 16.6666666667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.3333333333%; }
  .pull-lg-5 {
    right: 41.6666666667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.3333333333%; }
  .pull-lg-8 {
    right: 66.6666666667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.3333333333%; }
  .pull-lg-11 {
    right: 91.6666666667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.3333333333%; }
  .push-lg-2 {
    left: 16.6666666667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.3333333333%; }
  .push-lg-5 {
    left: 41.6666666667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.3333333333%; }
  .push-lg-8 {
    left: 66.6666666667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.3333333333%; }
  .push-lg-11 {
    left: 91.6666666667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    width: 8.3333333333%; }
  .col-xl-2 {
    width: 16.6666666667%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-4 {
    width: 33.3333333333%; }
  .col-xl-5 {
    width: 41.6666666667%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-7 {
    width: 58.3333333333%; }
  .col-xl-8 {
    width: 66.6666666667%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-10 {
    width: 83.3333333333%; }
  .col-xl-11 {
    width: 91.6666666667%; }
  .col-xl-12 {
    width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.3333333333%; }
  .pull-xl-2 {
    right: 16.6666666667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.3333333333%; }
  .pull-xl-5 {
    right: 41.6666666667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.3333333333%; }
  .pull-xl-8 {
    right: 66.6666666667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.3333333333%; }
  .pull-xl-11 {
    right: 91.6666666667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.3333333333%; }
  .push-xl-2 {
    left: 16.6666666667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.3333333333%; }
  .push-xl-5 {
    left: 41.6666666667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.3333333333%; }
  .push-xl-8 {
    left: 66.6666666667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.3333333333%; }
  .push-xl-11 {
    left: 91.6666666667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef; }
  .table tbody + tbody {
    border-top: 2px solid #eceeef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #eceeef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eceeef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8; }

.table-hover .table-success:hover {
  background-color: #d0e9c6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d0e9c6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7; }

.table-hover .table-info:hover {
  background-color: #c4e3f3; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c4e3f3; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3; }

.table-hover .table-warning:hover {
  background-color: #faf2cc; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #faf2cc; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede; }

.table-hover .table-danger:hover {
  background-color: #ebcccc; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ebcccc; }

.thead-inverse th {
  color: #fff;
  background-color: #292b2c; }

.thead-default th {
  color: #464a4c;
  background-color: #eceeef; }

.table-inverse {
  color: #fff;
  background-color: #292b2c; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #3b3e40; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #464a4c;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #636c72;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #eceeef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #464a4c;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(1rem - 1px * 2);
  padding-bottom: calc(1rem - 1px * 2);
  font-size: 1.125rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-static {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-static.form-control-sm, .input-group-sm > .form-control-static.form-control,
  .input-group-sm > .form-control-static.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-static.btn, .form-control-static.form-control-lg, .input-group-lg > .form-control-static.form-control,
  .input-group-lg > .form-control-static.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-static.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(3.6875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #636c72; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.form-control-feedback {
  margin-top: 0.25rem; }

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 3rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  background-size: 1.125rem 1.125rem; }

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .col-form-label,
.has-success .form-check-label,
.has-success .custom-control {
  color: #28a745; }

.has-success .form-control,
.has-success .custom-select,
.has-success .custom-file-control {
  border-color: #28a745; }

.has-success .input-group-addon {
  color: #28a745;
  background-color: #afecbd;
  border-color: #28a745; }

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"); }

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .col-form-label,
.has-warning .form-check-label,
.has-warning .custom-control {
  color: #fd7e14; }

.has-warning .form-control,
.has-warning .custom-select,
.has-warning .custom-file-control {
  border-color: #fd7e14; }

.has-warning .input-group-addon {
  color: #fd7e14;
  background-color: #ffedde;
  border-color: #fd7e14; }

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fd7e14' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"); }

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
  color: #dc3545; }

.has-danger .form-control,
.has-danger .custom-select,
.has-danger .custom-file-control {
  border-color: #dc3545; }

.has-danger .input-group-addon {
  color: #dc3545;
  background-color: #fae3e5;
  border-color: #dc3545; }

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-static {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0062cc;
    border-color: #005dc2; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    background-image: none;
    border-color: #005dc2; }

.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc; }
  .btn-secondary:hover {
    color: #292b2c;
    background-color: #e6e5e5;
    border-color: #adadad; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #fff;
    border-color: #ccc; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #292b2c;
    background-color: #e6e5e5;
    background-image: none;
    border-color: #adadad; }

.btn-info {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-info:hover {
    color: #fff;
    background-color: #199d76;
    border-color: #18946f; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 2px rgba(32, 201, 151, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #20c997;
    border-color: #20c997; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #199d76;
    background-image: none;
    border-color: #18946f; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7631; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    background-image: none;
    border-color: #1c7631; }

.btn-warning {
  color: #fff;
  background-color: #fd7e14;
  border-color: #fd7e14; }
  .btn-warning:hover {
    color: #fff;
    background-color: #dc6502;
    border-color: #d26102; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 2px rgba(253, 126, 20, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #fd7e14;
    border-color: #fd7e14; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #dc6502;
    background-image: none;
    border-color: #d26102; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bd2130;
    border-color: #b51f2e; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    background-image: none;
    border-color: #b51f2e; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #ccc;
  background-color: transparent;
  background-image: none;
  border-color: #ccc; }
  .btn-outline-secondary:hover {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ccc;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc; }

.btn-outline-info {
  color: #20c997;
  background-color: transparent;
  background-image: none;
  border-color: #20c997; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 2px rgba(32, 201, 151, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #20c997;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-warning {
  color: #fd7e14;
  background-color: transparent;
  background-image: none;
  border-color: #fd7e14; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #fd7e14;
    border-color: #fd7e14; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 2px rgba(253, 126, 20, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fd7e14;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #fd7e14;
    border-color: #fd7e14; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #636c72; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.5rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #292b2c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eceeef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #1d1e1f;
    text-decoration: none;
    background-color: #f7f7f9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #636c72;
    background-color: transparent; }

.show > .dropdown-menu {
  display: block; }

.show > a {
  outline: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #636c72;
  white-space: nowrap; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 0.125rem; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto;
    margin-bottom: 0; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
      z-index: 3; }
  .input-group-btn:not(:last-child) > .btn,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -1px; }
  .input-group-btn:not(:first-child) > .btn,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: -1px; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.75rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff; }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #eceeef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #636c72; }

.custom-control-indicator {
  position: absolute;
  top: 0.375rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #464a4c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #464a4c;
      background-color: #fff; }
  .custom-select:disabled {
    color: #636c72;
    background-color: #eceeef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #464a4c;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0 0.25rem 0.25rem 0; }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #636c72; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #eceeef #eceeef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #636c72;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #464a4c;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
  .nav-pills .nav-link.active,
  .show .nav-pills .nav-link {
    color: #fff;
    background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 575px) {
      .navbar > .container,
      .navbar > .container-fluid {
        width: 100%;
        margin-right: 0;
        margin-left: 0; } }

.navbar-brand {
  display: inline-block;
  padding-top: 0.390625rem;
  padding-bottom: 0.390625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575px) {
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-inverse .navbar-brand {
  color: white; }
  .navbar-inverse .navbar-brand:focus, .navbar-inverse .navbar-brand:hover {
    color: white; }

.navbar-inverse .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-inverse .navbar-nav .nav-link:focus, .navbar-inverse .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-inverse .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-inverse .navbar-nav .show > .nav-link,
.navbar-inverse .navbar-nav .active > .nav-link,
.navbar-inverse .navbar-nav .nav-link.show,
.navbar-inverse .navbar-nav .nav-link.active {
  color: white; }

.navbar-inverse .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-inverse .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-inverse .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem; }

.card-block {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem;
  word-break: break-all; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f7f7f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f7f7f9;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-primary {
  background-color: #007bff;
  border-color: #007bff; }
  .card-primary .card-header,
  .card-primary .card-footer {
    background-color: transparent; }

.card-success {
  background-color: #28a745;
  border-color: #28a745; }
  .card-success .card-header,
  .card-success .card-footer {
    background-color: transparent; }

.card-info {
  background-color: #20c997;
  border-color: #20c997; }
  .card-info .card-header,
  .card-info .card-footer {
    background-color: transparent; }

.card-warning {
  background-color: #fd7e14;
  border-color: #fd7e14; }
  .card-warning .card-header,
  .card-warning .card-footer {
    background-color: transparent; }

.card-danger {
  background-color: #dc3545;
  border-color: #dc3545; }
  .card-danger .card-header,
  .card-danger .card-footer {
    background-color: transparent; }

.card-outline-primary {
  background-color: transparent;
  border-color: #007bff; }
  .card-outline-primary .card-header,
  .card-outline-primary .card-footer {
    background-color: transparent;
    border-color: #007bff; }

.card-outline-secondary {
  background-color: transparent;
  border-color: #ccc; }
  .card-outline-secondary .card-header,
  .card-outline-secondary .card-footer {
    background-color: transparent;
    border-color: #ccc; }

.card-outline-info {
  background-color: transparent;
  border-color: #20c997; }
  .card-outline-info .card-header,
  .card-outline-info .card-footer {
    background-color: transparent;
    border-color: #20c997; }

.card-outline-success {
  background-color: transparent;
  border-color: #28a745; }
  .card-outline-success .card-header,
  .card-outline-success .card-footer {
    background-color: transparent;
    border-color: #28a745; }

.card-outline-warning {
  background-color: transparent;
  border-color: #fd7e14; }
  .card-outline-warning .card-header,
  .card-outline-warning .card-footer {
    background-color: transparent;
    border-color: #fd7e14; }

.card-outline-danger {
  background-color: transparent;
  border-color: #dc3545; }
  .card-outline-danger .card-header,
  .card-outline-danger .card-footer {
    background-color: transparent;
    border-color: #dc3545; }

.card-inverse {
  color: rgba(255, 255, 255, 0.65); }
  .card-inverse .card-header,
  .card-inverse .card-footer {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.2); }
  .card-inverse .card-header,
  .card-inverse .card-footer,
  .card-inverse .card-title,
  .card-inverse .card-blockquote {
    color: #fff; }
  .card-inverse .card-link,
  .card-inverse .card-text,
  .card-inverse .card-subtitle,
  .card-inverse .card-blockquote .blockquote-footer {
    color: rgba(255, 255, 255, 0.65); }
  .card-inverse .card-link:focus, .card-inverse .card-link:hover {
    color: #fff; }

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.5rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px); }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px; } }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eceeef;
  border-radius: 0.25rem; }
  .breadcrumb::after {
    display: block;
    clear: both;
    content: ""; }

.breadcrumb-item {
  float: left; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #636c72;
    content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #636c72; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #eceeef;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

a.badge:focus, a.badge:hover {
  color: #fff;
  text-decoration: none; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-default {
  background-color: #636c72; }
  .badge-default[href]:focus, .badge-default[href]:hover {
    background-color: #4b5257; }

.badge-primary {
  background-color: #007bff; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    background-color: #0062cc; }

.badge-success {
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    background-color: #1e7e34; }

.badge-info {
  background-color: #20c997; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    background-color: #199d76; }

.badge-warning {
  background-color: #fd7e14; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    background-color: #dc6502; }

.badge-danger {
  background-color: #dc3545; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    background-color: #bd2130; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d0e9c6; }
  .alert-success hr {
    border-top-color: #c1e2b3; }
  .alert-success .alert-link {
    color: #2b542c; }

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bcdff1; }
  .alert-info hr {
    border-top-color: #a6d5ec; }
  .alert-info .alert-link {
    color: #245269; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc; }
  .alert-warning hr {
    border-top-color: #f7ecb5; }
  .alert-warning .alert-link {
    color: #66512c; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebcccc; }
  .alert-danger hr {
    border-top-color: #e4b9b9; }
  .alert-danger .alert-link {
    color: #843534; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #eceeef;
  border-radius: 0.25rem; }

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #464a4c;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #464a4c;
    text-decoration: none;
    background-color: #f7f7f9; }
  .list-group-item-action:active {
    color: #292b2c;
    background-color: #eceeef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #636c72;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8; }

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #3c763d;
    background-color: #d0e9c6; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d; }

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7; }

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #31708f;
    background-color: #c4e3f3; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f; }

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #8a6d3b;
    background-color: #faf2cc; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b; }

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #a94442;
    background-color: #ebcccc; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.75; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eceeef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom {
    padding: 5px 0;
    margin-top: -3px; }
    .tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.tooltip-right, .tooltip.bs-tether-element-attached-left {
    padding: 0 5px;
    margin-left: 3px; }
    .tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
      top: 50%;
      left: 0;
      margin-top: -5px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.tooltip-bottom, .tooltip.bs-tether-element-attached-top {
    padding: 5px 0;
    margin-top: 3px; }
    .tooltip.tooltip-bottom .tooltip-inner::before, .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
      top: 0;
      left: 50%;
      margin-left: -5px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.tooltip-left, .tooltip.bs-tether-element-attached-right {
    padding: 0 5px;
    margin-left: -3px; }
    .tooltip.tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
      top: 50%;
      right: 0;
      margin-top: -5px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }
  .tooltip-inner::before {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover.popover-top, .popover.bs-tether-element-attached-bottom {
    margin-top: -10px; }
    .popover.popover-top::before, .popover.popover-top::after, .popover.bs-tether-element-attached-bottom::before, .popover.bs-tether-element-attached-bottom::after {
      left: 50%;
      border-bottom-width: 0; }
    .popover.popover-top::before, .popover.bs-tether-element-attached-bottom::before {
      bottom: -11px;
      margin-left: -11px;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .popover.popover-top::after, .popover.bs-tether-element-attached-bottom::after {
      bottom: -10px;
      margin-left: -10px;
      border-top-color: #fff; }
  .popover.popover-right, .popover.bs-tether-element-attached-left {
    margin-left: 10px; }
    .popover.popover-right::before, .popover.popover-right::after, .popover.bs-tether-element-attached-left::before, .popover.bs-tether-element-attached-left::after {
      top: 50%;
      border-left-width: 0; }
    .popover.popover-right::before, .popover.bs-tether-element-attached-left::before {
      left: -11px;
      margin-top: -11px;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .popover.popover-right::after, .popover.bs-tether-element-attached-left::after {
      left: -10px;
      margin-top: -10px;
      border-right-color: #fff; }
  .popover.popover-bottom, .popover.bs-tether-element-attached-top {
    margin-top: 10px; }
    .popover.popover-bottom::before, .popover.popover-bottom::after, .popover.bs-tether-element-attached-top::before, .popover.bs-tether-element-attached-top::after {
      left: 50%;
      border-top-width: 0; }
    .popover.popover-bottom::before, .popover.bs-tether-element-attached-top::before {
      top: -11px;
      margin-left: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .popover.popover-bottom::after, .popover.bs-tether-element-attached-top::after {
      top: -10px;
      margin-left: -10px;
      border-bottom-color: #fff; }
    .popover.popover-bottom .popover-title::before, .popover.bs-tether-element-attached-top .popover-title::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.popover-left, .popover.bs-tether-element-attached-right {
    margin-left: -10px; }
    .popover.popover-left::before, .popover.popover-left::after, .popover.bs-tether-element-attached-right::before, .popover.bs-tether-element-attached-right::after {
      top: 50%;
      border-right-width: 0; }
    .popover.popover-left::before, .popover.bs-tether-element-attached-right::before {
      right: -11px;
      margin-top: -11px;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .popover.popover-left::after, .popover.bs-tether-element-attached-right::after {
      right: -10px;
      margin-top: -10px;
      border-left-color: #fff; }

.popover-title {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-title:empty {
    display: none; }

.popover-content {
  padding: 9px 14px;
  color: #292b2c; }

.popover::before,
.popover::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover::before {
  content: "";
  border-width: 11px; }

.popover::after {
  content: "";
  border-width: 10px; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translate3d(0, 0, 0); }

.carousel-item-next,
.active.carousel-item-right {
  transform: translate3d(100%, 0, 0); }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translate3d(-100%, 0, 0); }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 1 0 auto;
    max-width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-faded {
  background-color: #f7f7f7; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6B717B !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #535860 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-gray {
  background-color: #6B717B !important; }

a.bg-gray:focus, a.bg-gray:hover {
  background-color: #535860 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-charcoal {
  background-color: #282B35 !important; }

a.bg-charcoal:focus, a.bg-charcoal:hover {
  background-color: #121318 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.rounded {
  border-radius: 0.5rem; }

.rounded-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem; }

.rounded-right {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.rounded-bottom {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.rounded-left {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.is-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.order-first {
  order: -1; }

.order-last {
  order: 1; }

.order-0 {
  order: 0; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 1; }
  .order-sm-0 {
    order: 0; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 1; }
  .order-md-0 {
    order: 0; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 1; }
  .order-lg-0 {
    order: 0; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 1; }
  .order-xl-0 {
    order: 0; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-80 {
  width: 80px !important; }

.w-100 {
  width: 100px !important; }

.w-115 {
  width: 115px !important; }

.w-150 {
  width: 150px !important; }

.w-180 {
  width: 180px !important; }

.w-220 {
  width: 220px !important; }

.w-280 {
  width: 280px !important; }

.w-320 {
  width: 320px !important; }

.h-80 {
  height: 80px !important; }

.h-100 {
  height: 100px !important; }

.h-115 {
  height: 115px !important; }

.h-150 {
  height: 150px !important; }

.h-180 {
  height: 180px !important; }

.h-220 {
  height: 220px !important; }

.h-280 {
  height: 280px !important; }

.h-320 {
  height: 320px !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.m-8 {
  margin: 6rem !important; }

.mt-8 {
  margin-top: 6rem !important; }

.mr-8 {
  margin-right: 6rem !important; }

.mb-8 {
  margin-bottom: 6rem !important; }

.ml-8 {
  margin-left: 6rem !important; }

.mx-8 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.my-8 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.m-10 {
  margin: 10rem !important; }

.mt-10 {
  margin-top: 10rem !important; }

.mr-10 {
  margin-right: 10rem !important; }

.mb-10 {
  margin-bottom: 10rem !important; }

.ml-10 {
  margin-left: 10rem !important; }

.mx-10 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.my-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.m-15 {
  margin: 15rem !important; }

.mt-15 {
  margin-top: 15rem !important; }

.mr-15 {
  margin-right: 15rem !important; }

.mb-15 {
  margin-bottom: 15rem !important; }

.ml-15 {
  margin-left: 15rem !important; }

.mx-15 {
  margin-right: 15rem !important;
  margin-left: 15rem !important; }

.my-15 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important; }

.m-20 {
  margin: 20rem !important; }

.mt-20 {
  margin-top: 20rem !important; }

.mr-20 {
  margin-right: 20rem !important; }

.mb-20 {
  margin-bottom: 20rem !important; }

.ml-20 {
  margin-left: 20rem !important; }

.mx-20 {
  margin-right: 20rem !important;
  margin-left: 20rem !important; }

.my-20 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.p-8 {
  padding: 6rem !important; }

.pt-8 {
  padding-top: 6rem !important; }

.pr-8 {
  padding-right: 6rem !important; }

.pb-8 {
  padding-bottom: 6rem !important; }

.pl-8 {
  padding-left: 6rem !important; }

.px-8 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.p-10 {
  padding: 10rem !important; }

.pt-10 {
  padding-top: 10rem !important; }

.pr-10 {
  padding-right: 10rem !important; }

.pb-10 {
  padding-bottom: 10rem !important; }

.pl-10 {
  padding-left: 10rem !important; }

.px-10 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.p-15 {
  padding: 15rem !important; }

.pt-15 {
  padding-top: 15rem !important; }

.pr-15 {
  padding-right: 15rem !important; }

.pb-15 {
  padding-bottom: 15rem !important; }

.pl-15 {
  padding-left: 15rem !important; }

.px-15 {
  padding-right: 15rem !important;
  padding-left: 15rem !important; }

.py-15 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important; }

.p-20 {
  padding: 20rem !important; }

.pt-20 {
  padding-top: 20rem !important; }

.pr-20 {
  padding-right: 20rem !important; }

.pb-20 {
  padding-bottom: 20rem !important; }

.pl-20 {
  padding-left: 20rem !important; }

.px-20 {
  padding-right: 20rem !important;
  padding-left: 20rem !important; }

.py-20 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-sm-8 {
    margin: 6rem !important; }
  .mt-sm-8 {
    margin-top: 6rem !important; }
  .mr-sm-8 {
    margin-right: 6rem !important; }
  .mb-sm-8 {
    margin-bottom: 6rem !important; }
  .ml-sm-8 {
    margin-left: 6rem !important; }
  .mx-sm-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-sm-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-sm-10 {
    margin: 10rem !important; }
  .mt-sm-10 {
    margin-top: 10rem !important; }
  .mr-sm-10 {
    margin-right: 10rem !important; }
  .mb-sm-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-10 {
    margin-left: 10rem !important; }
  .mx-sm-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .my-sm-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .m-sm-15 {
    margin: 15rem !important; }
  .mt-sm-15 {
    margin-top: 15rem !important; }
  .mr-sm-15 {
    margin-right: 15rem !important; }
  .mb-sm-15 {
    margin-bottom: 15rem !important; }
  .ml-sm-15 {
    margin-left: 15rem !important; }
  .mx-sm-15 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .my-sm-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .m-sm-20 {
    margin: 20rem !important; }
  .mt-sm-20 {
    margin-top: 20rem !important; }
  .mr-sm-20 {
    margin-right: 20rem !important; }
  .mb-sm-20 {
    margin-bottom: 20rem !important; }
  .ml-sm-20 {
    margin-left: 20rem !important; }
  .mx-sm-20 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .my-sm-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-sm-8 {
    padding: 6rem !important; }
  .pt-sm-8 {
    padding-top: 6rem !important; }
  .pr-sm-8 {
    padding-right: 6rem !important; }
  .pb-sm-8 {
    padding-bottom: 6rem !important; }
  .pl-sm-8 {
    padding-left: 6rem !important; }
  .px-sm-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-sm-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-sm-10 {
    padding: 10rem !important; }
  .pt-sm-10 {
    padding-top: 10rem !important; }
  .pr-sm-10 {
    padding-right: 10rem !important; }
  .pb-sm-10 {
    padding-bottom: 10rem !important; }
  .pl-sm-10 {
    padding-left: 10rem !important; }
  .px-sm-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .py-sm-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .p-sm-15 {
    padding: 15rem !important; }
  .pt-sm-15 {
    padding-top: 15rem !important; }
  .pr-sm-15 {
    padding-right: 15rem !important; }
  .pb-sm-15 {
    padding-bottom: 15rem !important; }
  .pl-sm-15 {
    padding-left: 15rem !important; }
  .px-sm-15 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .py-sm-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .p-sm-20 {
    padding: 20rem !important; }
  .pt-sm-20 {
    padding-top: 20rem !important; }
  .pr-sm-20 {
    padding-right: 20rem !important; }
  .pb-sm-20 {
    padding-bottom: 20rem !important; }
  .pl-sm-20 {
    padding-left: 20rem !important; }
  .px-sm-20 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .py-sm-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-md-8 {
    margin: 6rem !important; }
  .mt-md-8 {
    margin-top: 6rem !important; }
  .mr-md-8 {
    margin-right: 6rem !important; }
  .mb-md-8 {
    margin-bottom: 6rem !important; }
  .ml-md-8 {
    margin-left: 6rem !important; }
  .mx-md-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-md-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-md-10 {
    margin: 10rem !important; }
  .mt-md-10 {
    margin-top: 10rem !important; }
  .mr-md-10 {
    margin-right: 10rem !important; }
  .mb-md-10 {
    margin-bottom: 10rem !important; }
  .ml-md-10 {
    margin-left: 10rem !important; }
  .mx-md-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .my-md-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .m-md-15 {
    margin: 15rem !important; }
  .mt-md-15 {
    margin-top: 15rem !important; }
  .mr-md-15 {
    margin-right: 15rem !important; }
  .mb-md-15 {
    margin-bottom: 15rem !important; }
  .ml-md-15 {
    margin-left: 15rem !important; }
  .mx-md-15 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .my-md-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .m-md-20 {
    margin: 20rem !important; }
  .mt-md-20 {
    margin-top: 20rem !important; }
  .mr-md-20 {
    margin-right: 20rem !important; }
  .mb-md-20 {
    margin-bottom: 20rem !important; }
  .ml-md-20 {
    margin-left: 20rem !important; }
  .mx-md-20 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .my-md-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-md-8 {
    padding: 6rem !important; }
  .pt-md-8 {
    padding-top: 6rem !important; }
  .pr-md-8 {
    padding-right: 6rem !important; }
  .pb-md-8 {
    padding-bottom: 6rem !important; }
  .pl-md-8 {
    padding-left: 6rem !important; }
  .px-md-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-md-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-md-10 {
    padding: 10rem !important; }
  .pt-md-10 {
    padding-top: 10rem !important; }
  .pr-md-10 {
    padding-right: 10rem !important; }
  .pb-md-10 {
    padding-bottom: 10rem !important; }
  .pl-md-10 {
    padding-left: 10rem !important; }
  .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .p-md-15 {
    padding: 15rem !important; }
  .pt-md-15 {
    padding-top: 15rem !important; }
  .pr-md-15 {
    padding-right: 15rem !important; }
  .pb-md-15 {
    padding-bottom: 15rem !important; }
  .pl-md-15 {
    padding-left: 15rem !important; }
  .px-md-15 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .py-md-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .p-md-20 {
    padding: 20rem !important; }
  .pt-md-20 {
    padding-top: 20rem !important; }
  .pr-md-20 {
    padding-right: 20rem !important; }
  .pb-md-20 {
    padding-bottom: 20rem !important; }
  .pl-md-20 {
    padding-left: 20rem !important; }
  .px-md-20 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .py-md-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-lg-8 {
    margin: 6rem !important; }
  .mt-lg-8 {
    margin-top: 6rem !important; }
  .mr-lg-8 {
    margin-right: 6rem !important; }
  .mb-lg-8 {
    margin-bottom: 6rem !important; }
  .ml-lg-8 {
    margin-left: 6rem !important; }
  .mx-lg-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-lg-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-lg-10 {
    margin: 10rem !important; }
  .mt-lg-10 {
    margin-top: 10rem !important; }
  .mr-lg-10 {
    margin-right: 10rem !important; }
  .mb-lg-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-10 {
    margin-left: 10rem !important; }
  .mx-lg-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .my-lg-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .m-lg-15 {
    margin: 15rem !important; }
  .mt-lg-15 {
    margin-top: 15rem !important; }
  .mr-lg-15 {
    margin-right: 15rem !important; }
  .mb-lg-15 {
    margin-bottom: 15rem !important; }
  .ml-lg-15 {
    margin-left: 15rem !important; }
  .mx-lg-15 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .my-lg-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .m-lg-20 {
    margin: 20rem !important; }
  .mt-lg-20 {
    margin-top: 20rem !important; }
  .mr-lg-20 {
    margin-right: 20rem !important; }
  .mb-lg-20 {
    margin-bottom: 20rem !important; }
  .ml-lg-20 {
    margin-left: 20rem !important; }
  .mx-lg-20 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .my-lg-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-lg-8 {
    padding: 6rem !important; }
  .pt-lg-8 {
    padding-top: 6rem !important; }
  .pr-lg-8 {
    padding-right: 6rem !important; }
  .pb-lg-8 {
    padding-bottom: 6rem !important; }
  .pl-lg-8 {
    padding-left: 6rem !important; }
  .px-lg-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-lg-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-lg-10 {
    padding: 10rem !important; }
  .pt-lg-10 {
    padding-top: 10rem !important; }
  .pr-lg-10 {
    padding-right: 10rem !important; }
  .pb-lg-10 {
    padding-bottom: 10rem !important; }
  .pl-lg-10 {
    padding-left: 10rem !important; }
  .px-lg-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .p-lg-15 {
    padding: 15rem !important; }
  .pt-lg-15 {
    padding-top: 15rem !important; }
  .pr-lg-15 {
    padding-right: 15rem !important; }
  .pb-lg-15 {
    padding-bottom: 15rem !important; }
  .pl-lg-15 {
    padding-left: 15rem !important; }
  .px-lg-15 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .py-lg-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .p-lg-20 {
    padding: 20rem !important; }
  .pt-lg-20 {
    padding-top: 20rem !important; }
  .pr-lg-20 {
    padding-right: 20rem !important; }
  .pb-lg-20 {
    padding-bottom: 20rem !important; }
  .pl-lg-20 {
    padding-left: 20rem !important; }
  .px-lg-20 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .py-lg-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-xl-8 {
    margin: 6rem !important; }
  .mt-xl-8 {
    margin-top: 6rem !important; }
  .mr-xl-8 {
    margin-right: 6rem !important; }
  .mb-xl-8 {
    margin-bottom: 6rem !important; }
  .ml-xl-8 {
    margin-left: 6rem !important; }
  .mx-xl-8 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-xl-8 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-xl-10 {
    margin: 10rem !important; }
  .mt-xl-10 {
    margin-top: 10rem !important; }
  .mr-xl-10 {
    margin-right: 10rem !important; }
  .mb-xl-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-10 {
    margin-left: 10rem !important; }
  .mx-xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .my-xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .m-xl-15 {
    margin: 15rem !important; }
  .mt-xl-15 {
    margin-top: 15rem !important; }
  .mr-xl-15 {
    margin-right: 15rem !important; }
  .mb-xl-15 {
    margin-bottom: 15rem !important; }
  .ml-xl-15 {
    margin-left: 15rem !important; }
  .mx-xl-15 {
    margin-right: 15rem !important;
    margin-left: 15rem !important; }
  .my-xl-15 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important; }
  .m-xl-20 {
    margin: 20rem !important; }
  .mt-xl-20 {
    margin-top: 20rem !important; }
  .mr-xl-20 {
    margin-right: 20rem !important; }
  .mb-xl-20 {
    margin-bottom: 20rem !important; }
  .ml-xl-20 {
    margin-left: 20rem !important; }
  .mx-xl-20 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .my-xl-20 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-xl-8 {
    padding: 6rem !important; }
  .pt-xl-8 {
    padding-top: 6rem !important; }
  .pr-xl-8 {
    padding-right: 6rem !important; }
  .pb-xl-8 {
    padding-bottom: 6rem !important; }
  .pl-xl-8 {
    padding-left: 6rem !important; }
  .px-xl-8 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xl-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-xl-10 {
    padding: 10rem !important; }
  .pt-xl-10 {
    padding-top: 10rem !important; }
  .pr-xl-10 {
    padding-right: 10rem !important; }
  .pb-xl-10 {
    padding-bottom: 10rem !important; }
  .pl-xl-10 {
    padding-left: 10rem !important; }
  .px-xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .py-xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .p-xl-15 {
    padding: 15rem !important; }
  .pt-xl-15 {
    padding-top: 15rem !important; }
  .pr-xl-15 {
    padding-right: 15rem !important; }
  .pb-xl-15 {
    padding-bottom: 15rem !important; }
  .pl-xl-15 {
    padding-left: 15rem !important; }
  .px-xl-15 {
    padding-right: 15rem !important;
    padding-left: 15rem !important; }
  .py-xl-15 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important; }
  .p-xl-20 {
    padding: 20rem !important; }
  .pt-xl-20 {
    padding-top: 20rem !important; }
  .pr-xl-20 {
    padding-right: 20rem !important; }
  .pb-xl-20 {
    padding-bottom: 20rem !important; }
  .pl-xl-20 {
    padding-left: 20rem !important; }
  .px-xl-20 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .py-xl-20 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #6B717B !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #535860 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-gray {
  color: #6B717B !important; }

a.text-gray:focus, a.text-gray:hover {
  color: #535860 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-charcoal {
  color: #282B35 !important; }

a.text-charcoal:focus, a.text-charcoal:hover {
  color: #121318 !important; }

.text-muted {
  color: #636c72 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 0) {
  .minw-xs-0 {
    min-width: 0px !important; }
  .minw-xs-80 {
    min-width: 80px !important; }
  .minw-xs-100 {
    min-width: 100px !important; }
  .minw-xs-150 {
    min-width: 150px !important; }
  .minw-xs-180 {
    min-width: 180px !important; }
  .minw-xs-220 {
    min-width: 220px !important; }
  .minw-xs-280 {
    min-width: 280px !important; }
  .minw-xs-320 {
    min-width: 320px !important; }
  .minw-xs-auto {
    min-width: auto !important; } }

@media (min-width: 576px) {
  .minw-sm-0 {
    min-width: 0px !important; }
  .minw-sm-80 {
    min-width: 80px !important; }
  .minw-sm-100 {
    min-width: 100px !important; }
  .minw-sm-150 {
    min-width: 150px !important; }
  .minw-sm-180 {
    min-width: 180px !important; }
  .minw-sm-220 {
    min-width: 220px !important; }
  .minw-sm-280 {
    min-width: 280px !important; }
  .minw-sm-320 {
    min-width: 320px !important; }
  .minw-sm-auto {
    min-width: auto !important; } }

@media (min-width: 768px) {
  .minw-md-0 {
    min-width: 0px !important; }
  .minw-md-80 {
    min-width: 80px !important; }
  .minw-md-100 {
    min-width: 100px !important; }
  .minw-md-150 {
    min-width: 150px !important; }
  .minw-md-180 {
    min-width: 180px !important; }
  .minw-md-220 {
    min-width: 220px !important; }
  .minw-md-280 {
    min-width: 280px !important; }
  .minw-md-320 {
    min-width: 320px !important; }
  .minw-md-auto {
    min-width: auto !important; } }

@media (min-width: 992px) {
  .minw-lg-0 {
    min-width: 0px !important; }
  .minw-lg-80 {
    min-width: 80px !important; }
  .minw-lg-100 {
    min-width: 100px !important; }
  .minw-lg-150 {
    min-width: 150px !important; }
  .minw-lg-180 {
    min-width: 180px !important; }
  .minw-lg-220 {
    min-width: 220px !important; }
  .minw-lg-280 {
    min-width: 280px !important; }
  .minw-lg-320 {
    min-width: 320px !important; }
  .minw-lg-auto {
    min-width: auto !important; } }

@media (min-width: 1200px) {
  .minw-xl-0 {
    min-width: 0px !important; }
  .minw-xl-80 {
    min-width: 80px !important; }
  .minw-xl-100 {
    min-width: 100px !important; }
  .minw-xl-150 {
    min-width: 150px !important; }
  .minw-xl-180 {
    min-width: 180px !important; }
  .minw-xl-220 {
    min-width: 220px !important; }
  .minw-xl-280 {
    min-width: 280px !important; }
  .minw-xl-320 {
    min-width: 320px !important; }
  .minw-xl-auto {
    min-width: auto !important; } }

@media (min-width: 0) {
  .minh-xs-0 {
    min-height: 0px !important; }
  .minh-xs-80 {
    min-height: 80px !important; }
  .minh-xs-100 {
    min-height: 100px !important; }
  .minh-xs-150 {
    min-height: 150px !important; }
  .minh-xs-180 {
    min-height: 180px !important; }
  .minh-xs-220 {
    min-height: 220px !important; }
  .minh-xs-280 {
    min-height: 280px !important; }
  .minh-xs-320 {
    min-height: 320px !important; }
  .minh-xs-auto {
    min-height: auto !important; } }

@media (min-width: 576px) {
  .minh-sm-0 {
    min-height: 0px !important; }
  .minh-sm-80 {
    min-height: 80px !important; }
  .minh-sm-100 {
    min-height: 100px !important; }
  .minh-sm-150 {
    min-height: 150px !important; }
  .minh-sm-180 {
    min-height: 180px !important; }
  .minh-sm-220 {
    min-height: 220px !important; }
  .minh-sm-280 {
    min-height: 280px !important; }
  .minh-sm-320 {
    min-height: 320px !important; }
  .minh-sm-auto {
    min-height: auto !important; } }

@media (min-width: 768px) {
  .minh-md-0 {
    min-height: 0px !important; }
  .minh-md-80 {
    min-height: 80px !important; }
  .minh-md-100 {
    min-height: 100px !important; }
  .minh-md-150 {
    min-height: 150px !important; }
  .minh-md-180 {
    min-height: 180px !important; }
  .minh-md-220 {
    min-height: 220px !important; }
  .minh-md-280 {
    min-height: 280px !important; }
  .minh-md-320 {
    min-height: 320px !important; }
  .minh-md-auto {
    min-height: auto !important; } }

@media (min-width: 992px) {
  .minh-lg-0 {
    min-height: 0px !important; }
  .minh-lg-80 {
    min-height: 80px !important; }
  .minh-lg-100 {
    min-height: 100px !important; }
  .minh-lg-150 {
    min-height: 150px !important; }
  .minh-lg-180 {
    min-height: 180px !important; }
  .minh-lg-220 {
    min-height: 220px !important; }
  .minh-lg-280 {
    min-height: 280px !important; }
  .minh-lg-320 {
    min-height: 320px !important; }
  .minh-lg-auto {
    min-height: auto !important; } }

@media (min-width: 1200px) {
  .minh-xl-0 {
    min-height: 0px !important; }
  .minh-xl-80 {
    min-height: 80px !important; }
  .minh-xl-100 {
    min-height: 100px !important; }
  .minh-xl-150 {
    min-height: 150px !important; }
  .minh-xl-180 {
    min-height: 180px !important; }
  .minh-xl-220 {
    min-height: 220px !important; }
  .minh-xl-280 {
    min-height: 280px !important; }
  .minh-xl-320 {
    min-height: 320px !important; }
  .minh-xl-auto {
    min-height: auto !important; } }

body {
  background-color: #F2F2F2;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.valign-bottom {
  vertical-align: bottom !important; }

.fw-thin {
  font-weight: 100; }

.fw-extralight, .fw-ultralight {
  font-weight: 200; }

.fw-light {
  font-weight: 300; }

.fw-normal, .fw-regular, .fw-book {
  font-weight: 400; }

.fw-medium {
  font-weight: 500; }

.fw-semibold, .fw-demibold {
  font-weight: 600; }

.fw-bold {
  font-weight: 700; }

.fw-extrabold, .fw-ultrabold {
  font-weight: 800; }

.fw-black, .fw-heavy {
  font-weight: 900; }

/* Rectangle 13: */
.material-form {
  /* -- Input styles ---------------------------------- */
  /* -- label styles ---------------------------------- */
  /* active state */
  /* input colors ---- */
  /* -- highlighter styles ------------------------------ */
  /* active state */
  /* -- highlighter animation --------------------------- */
  /* -- bar styles -------------------------------------- */
  /* active state */ }
  .material-form .form-group {
    position: relative;
    margin-top: 35px;
    margin-bottom: 20px; }
  .material-form .input-group {
    position: relative; }
  .material-form .form-control {
    display: block;
    width: 100%;
    border: none;
    border-radius: 0 !important;
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid #E4E7EC; }
  .material-form input.form-control {
    height: 36px; }
  .material-form .input-group .form-control {
    position: relative;
    z-index: inherit;
    float: inherit;
    width: 100%;
    margin-bottom: 0; }
  .material-form .form-control:focus {
    border-color: #E4E7EC;
    outline: none;
    box-shadow: none; }
  .material-form label {
    position: absolute;
    top: -18px;
    color: #999;
    font-size: 12px;
    font-weight: 300;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all; }
  .material-form .form-horizontal .control-label {
    position: relative;
    top: 0;
    margin-bottom: 0; }
  .material-form .float-label {
    left: 0;
    top: 7px;
    font-size: 16px;
    pointer-events: none; }
  .material-form .form-control:focus ~ .float-label, .material-form .form-control:valid ~ .float-label {
    top: -18px;
    font-size: 12px; }
  .material-form .float-label ~ .form-control:focus, .material-form .float-label ~ .form-control:valid {
    top: -18px;
    font-size: 12px; }
  .material-form .form-control:focus ~ label {
    color: #47AAFF; }
  .material-form .form-control:focus ~ .form-bar:before, .material-form .form-control:focus ~ .form-bar:after {
    background: #47AAFF; }
  .material-form .form-highlight {
    position: absolute;
    height: 60%;
    width: 60px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.4; }
  .material-form .form-control:focus ~ .form-highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease; }

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

@-moz-keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }

@keyframes inputHighlighter {
  from {
    background: #5264AE; }
  to {
    width: 0;
    background: transparent; } }
  .material-form .form-bar {
    position: relative;
    display: block;
    width: 100%; }
  .material-form .form-bar:before, .material-form .form-bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all; }
  .material-form .form-bar:before {
    left: 50%; }
  .material-form .form-bar:after {
    right: 50%; }
  .material-form .form-control:focus ~ .form-bar:before, .material-form .form-control:focus ~ .form-bar:after {
    width: 50%; }
  @media (min-width: 768px) {
    .material-form .form-horizontal .control-label {
      font-size: 16px; } }

.btn-rainbow-primary {
  color: #fff;
  background-color: #336DFF;
  border-color: #7963FF;
  background-image: linear-gradient(90deg, #336DFF 0%, #7963FF 100%);
  border-width: 0;
  background-clip: padding-box; }
  .btn-rainbow-primary:hover {
    color: #fff;
    background-color: #0049ff;
    border-color: #4426ff; }
  .btn-rainbow-primary:focus, .btn-rainbow-primary.focus {
    box-shadow: 0 0 0 2px rgba(121, 99, 255, 0.5); }
  .btn-rainbow-primary.disabled, .btn-rainbow-primary:disabled {
    background-color: #336DFF;
    border-color: #7963FF; }
  .btn-rainbow-primary:active, .btn-rainbow-primary.active,
  .show > .btn-rainbow-primary.dropdown-toggle {
    color: #fff;
    background-color: #0049ff;
    background-image: none;
    border-color: #4426ff; }
  .btn-rainbow-primary.has-shadow {
    box-shadow: 0 4px 12px 0 rgba(26, 41, 211, 0.6); }
  .btn-rainbow-primary:hover {
    background-image: linear-gradient(-90deg, #336DFF 0%, #7963FF 100%); }

.btn-rainbow-info {
  color: #fff;
  background-color: #31DAFF;
  border-color: #3AF3FF;
  background-image: linear-gradient(90deg, #31DAFF 0%, #538DFF 100%);
  background-clip: padding-box;
  border-width: 0; }
  .btn-rainbow-info:hover {
    color: #fff;
    background-color: #00d0fd;
    border-color: #00ecfc; }
  .btn-rainbow-info:focus, .btn-rainbow-info.focus {
    box-shadow: 0 0 0 2px rgba(58, 243, 255, 0.5); }
  .btn-rainbow-info.disabled, .btn-rainbow-info:disabled {
    background-color: #31DAFF;
    border-color: #3AF3FF; }
  .btn-rainbow-info:active, .btn-rainbow-info.active,
  .show > .btn-rainbow-info.dropdown-toggle {
    color: #fff;
    background-color: #00d0fd;
    background-image: none;
    border-color: #00ecfc; }
  .btn-rainbow-info:hover {
    background-image: linear-gradient(-90deg, #31DAFF 0%, #538DFF 100%); }

.btn-rainbow-warning {
  color: #fff;
  background-color: #FFA466;
  border-color: #FF5360;
  background-image: linear-gradient(90deg, #FFA466 0%, #FF5360 100%);
  border-width: 0;
  background-clip: padding-box; }
  .btn-rainbow-warning:hover {
    color: #fff;
    background-color: #ff8633;
    border-color: #ff1627; }
  .btn-rainbow-warning:focus, .btn-rainbow-warning.focus {
    box-shadow: 0 0 0 2px rgba(255, 83, 96, 0.5); }
  .btn-rainbow-warning.disabled, .btn-rainbow-warning:disabled {
    background-color: #FFA466;
    border-color: #FF5360; }
  .btn-rainbow-warning:active, .btn-rainbow-warning.active,
  .show > .btn-rainbow-warning.dropdown-toggle {
    color: #fff;
    background-color: #ff8633;
    background-image: none;
    border-color: #ff1627; }
  .btn-rainbow-warning.btn-stacked {
    padding: 14px 30px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 30px; }
  .btn-rainbow-warning.has-shadow {
    box-shadow: 0 4px 12px 0 rgba(255, 55, 68, 0.6); }
  .btn-rainbow-warning:hover {
    background-image: linear-gradient(-90deg, #FFA466 0%, #FF5360 100%); }

.btn-white {
  color: #383C41;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #383C41;
    background-color: #e6e5e5;
    border-color: #e0e0e0; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:active, .btn-white.active,
  .show > .btn-white.dropdown-toggle {
    color: #383C41;
    background-color: #e6e5e5;
    background-image: none;
    border-color: #e0e0e0; }
  .btn-white.has-shadow {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }

.btn-outline.btn-lg, .btn-group-lg > .btn-outline.btn {
  padding: 0.875rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-outline-blue {
  color: #47AAFF;
  background-color: transparent;
  background-image: none;
  border-color: #47AAFF;
  border-width: 2px; }
  .btn-outline-blue:hover {
    color: #ffffff;
    background-color: #47AAFF;
    border-color: #47AAFF; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 2px rgba(71, 170, 255, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #47AAFF;
    background-color: transparent; }
  .btn-outline-blue:active, .btn-outline-blue.active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #47AAFF;
    border-color: #47AAFF; }

.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  border-width: 2px; }
  .btn-outline-white:hover {
    color: #343a40;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:active, .btn-outline-white.active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #343a40;
    background-color: #fff;
    border-color: #fff; }

.form-control-rounded {
  border-radius: 30px; }

.form-control-hero {
  padding: 20px 40px;
  font-size: 16px;
  line-height: 1;
  border-radius: 40px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2); }
  .form-control-hero::placeholder {
    color: #A5ADBB; }

.stacked-btn-field {
  position: relative; }
  .stacked-btn-field .btn {
    position: absolute;
    right: 4px;
    top: 4px; }

.rainbow-form .form-top {
  z-index: 10; }

.rainbow-form-1 {
  background: #fff; }
  .rainbow-form-1 .blue-shade {
    background: url("/images/forms-img/21f9933d308846efa3d7b921b3dd01b6553a0223/form-1.png") #fff no-repeat center center;
    position: absolute;
    height: 420px;
    width: 100%; }
  .rainbow-form-1 .form-top {
    margin-top: 109px; }
    .rainbow-form-1 .form-top h2 {
      color: #fff; }
    .rainbow-form-1 .form-top p {
      color: #fff; }
  .rainbow-form-1 .card {
    margin-top: 88px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0; }
    .rainbow-form-1 .card .card-block {
      padding: 4.44rem 5rem 5rem; }
    .rainbow-form-1 .card h5 {
      margin-bottom: 44px; }

.bordered-top-when-active {
  border-top: 4px solid transparent; }
  .bordered-top-when-active.active {
    border-top: 4px solid #fff; }

.iphone-stack {
  position: relative; }
  .iphone-stack.cut-from-bottom {
    bottom: -65px; }
  .iphone-stack > :first-child {
    right: -60px; }
  .iphone-stack > :last-child {
    left: -60px; }
  .iphone-stack .is-in-front {
    position: relative;
    z-index: 556; }

.iphone-white {
  background: #FFFFFF;
  box-shadow: inset 0 4px 7px 1px #fff, inset 0 -5px 20px rgba(173, 186, 204, 0.25), 0 2px 6px rgba(0, 21, 64, 0.14), 0 10px 20px rgba(0, 21, 64, 0.05);
  width: 298px;
  height: 620px;
  border-radius: 35px;
  position: relative; }
  .iphone-white.iphone-sm {
    width: 278px;
    height: 580px;
    border-radius: 30px; }
    .iphone-white.iphone-sm .iphone-screen {
      width: 258px;
      height: 450px; }
  .iphone-white .iphone-home-button {
    background-image: linear-gradient(180deg, #E7EAF0 0%, #FFFFFF 100%);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -22px; }
  .iphone-white .iphone-speaker {
    width: 58px;
    height: 5px;
    background-image: linear-gradient(-180deg, #E7EAF0 0%, #FFFFFF 100%);
    border-radius: 6px;
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -29px; }
  .iphone-white .iphone-front-camera {
    background-image: linear-gradient(-180deg, #E7EAF0 0%, #FFFFFF 100%);
    height: 5px;
    width: 5px;
    border-radius: 50%;
    position: absolute;
    top: 30px;
    left: 95px; }
  .iphone-white .iphone-screen {
    background: #FFFFFF;
    border: 2px solid #E3E9EF;
    border-radius: 4px;
    width: 278px;
    height: 490px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%); }
    .iphone-white .iphone-screen img {
      width: 100%; }

.avatar.has-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4); }

.avatar-rounded {
  border-radius: 50%; }

.avatar-84 {
  width: 84px;
  height: 84px; }

.avatar-112 {
  width: 112px;
  height: 112px; }

.stacked-avatars {
  position: relative; }
  .stacked-avatars :first-child {
    margin-right: -15px; }
  .stacked-avatars :nth-child(2) {
    position: relative;
    z-index: 557; }
  .stacked-avatars :last-child {
    margin-left: -15px; }

.rainbow-hero-1 {
  background: url("/images/heroes-img/5fb0f0905d84e5e539ab16fee7d5dc9b3bd7ef3b/hero-1.jpg") #fff no-repeat center center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25); }
  .rainbow-hero-1 .hero-content {
    height: 800px; }
  .rainbow-hero-1 .hero-info h1 {
    color: #383C41; }
  .rainbow-hero-1 .hero-info p {
    color: #6B717B; }

.rainbow-hero-2 {
  background-image: linear-gradient(to right, #31DAFF 0%, #538DFF 100%);
  background-repeat: repeat-x;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden; }
  .rainbow-hero-2 .top-left-shade {
    position: absolute;
    background-image: linear-gradient(to right, #31D9FF 0%, #3E8DFF 100%);
    background-repeat: repeat-x;
    top: 0;
    left: 0;
    width: 55%;
    height: 180px; }
  .rainbow-hero-2 .top-right-shade {
    position: absolute;
    background-image: linear-gradient(to right, #317AFF 0%, #538DFF 100%);
    background-repeat: repeat-x;
    transform: skew(-12deg);
    top: 0;
    right: -5%;
    width: 60%;
    height: 180px; }
  .rainbow-hero-2 .bottom-shade {
    position: absolute;
    background-image: linear-gradient(to right, #39FAFF 0%, #49A5FF 100%);
    background-repeat: repeat-x;
    bottom: 0;
    left: 0;
    width: 70%;
    height: 180px; }
  .rainbow-hero-2 .hero-content {
    position: relative;
    color: #fff; }
    .rainbow-hero-2 .hero-content h1 {
      letter-spacing: 2px; }

.rainbow-hero-3 {
  background: url("/images/heroes-img/bf1df504d4f6ca6e375d3489ea2c560f0a9d5bf1/headhones.jpg") no-repeat 20% bottom #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25); }
  .rainbow-hero-3 .hero-content {
    min-height: 800px; }
  .rainbow-hero-3 .nav-link {
    font-size: 14px;
    color: #A5ADBB;
    font-weight: 300; }
    .rainbow-hero-3 .nav-link.active {
      color: #47AAFF;
      font-weight: 500; }
  .rainbow-hero-3 .hero-info h1 {
    color: #47AAFF; }
  .rainbow-hero-3 .hero-info p {
    color: #6B717B; }

.rainbow-hero-4 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  background-image: linear-gradient(270deg, #4641FF, #795DFF);
  background-repeat: repeat-x;
  overflow: hidden; }
  .rainbow-hero-4 .hero-content {
    min-height: 600px;
    color: #fff; }
  .rainbow-hero-4 .top-left-shade {
    background-image: linear-gradient(90deg, #7435FF, #644EFF);
    background-repeat: repeat-x;
    height: 265px;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0; }
  .rainbow-hero-4 .top-right-shade {
    background-image: linear-gradient(-90deg, #4641FF, #592EFF);
    background-repeat: repeat-x;
    transform: skew(12deg);
    height: 265px;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0; }
  .rainbow-hero-4 .bottom-shade {
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(-90deg, #4641FF 0%, #4F3EF6 50%, #795DFF 100%); }

.rainbow-hero-5 {
  background: url("/images/heroes-img/a8cee0313a21e2a0bcd862dfbe26b2b2e6160a8e/hero-5.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  height: 900px; }
  .rainbow-hero-5 .hero-content {
    min-height: 800px;
    color: #FFFFFF; }
  .rainbow-hero-5 .hero-info p {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 500; }
  .rainbow-hero-5 .hero-info h1 {
    font-size: 64px;
    color: #FFFFFF;
    font-weight: 500;
    letter-spacing: 2px; }

.rainbow-hero-6 {
  background-image: linear-gradient(to right, #4697FF 0%, #41C0FF 100%);
  background-repeat: repeat-x;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25); }
  .rainbow-hero-6 .hero-content {
    position: relative;
    min-height: 800px;
    background: url("/1d9d59f96f921713527af8b1b21c06c48e40d8d9/f5f19/images/heroes-img/confetti.svg") no-repeat center 80%; }
  .rainbow-hero-6 .hero-subject {
    position: absolute;
    width: 100%;
    left: 0;
    top: 10%;
    font-size: 144px;
    letter-spacing: 45px;
    font-weight: 700;
    color: #3892F8;
    text-transform: uppercase;
    text-align: center; }
  .rainbow-hero-6 .hero-model {
    width: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .rainbow-hero-6 .hero-info {
    position: relative; }
    .rainbow-hero-6 .hero-info h1 {
      display: inline-block;
      font-size: 72px;
      font-weight: 600;
      letter-spacing: 3px;
      color: #FFFFFF;
      text-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
      position: relative;
      z-index: 2; }
      .rainbow-hero-6 .hero-info h1:before {
        content: "";
        position: absolute;
        height: 12px;
        width: 130%;
        background-color: #33FFBE;
        top: 50%;
        left: -15%;
        z-index: -1; }

.rainbow-hero-7 {
  background-color: #fff; }
  .rainbow-hero-7 .hero-content {
    min-height: 800px;
    position: relative; }
    .rainbow-hero-7 .hero-content:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      background: url("/images/heroes-img/e2b00e9e9cb125b286f7fefbf3ba08c9d6e41860/legs.png") no-repeat center bottom;
      z-index: 1; }
  .rainbow-hero-7 .hero-subject {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    color: #F4F5F7;
    font-size: 144px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 40px;
    z-index: 0; }
  .rainbow-hero-7 .navbar-gradient {
    background-image: linear-gradient(to right, #31DAFF 0%, #538DFF 100%);
    background-repeat: repeat-x;
    position: relative;
    z-index: 555; }
  .rainbow-hero-7 .nav-link {
    font-size: 14px;
    color: #fff; }
  .rainbow-hero-7 .hero-info {
    position: relative;
    z-index: 555; }
    .rainbow-hero-7 .hero-info h1.first {
      font-size: 6rem;
      color: #FFD15D;
      line-height: 112px;
      font-weight: 800;
      text-transform: uppercase; }
      @media (max-width: 786px) {
        .rainbow-hero-7 .hero-info h1.first {
          font-size: 3rem;
          line-height: 0px; } }
    .rainbow-hero-7 .hero-info h1.second {
      font-size: 6rem;
      color: #6163FF;
      line-height: 112px;
      font-weight: 800;
      text-transform: uppercase; }
      @media (max-width: 786px) {
        .rainbow-hero-7 .hero-info h1.second {
          font-size: 3rem; } }

.rainbow-hero-8 {
  background-image: linear-gradient(to right, #4641FF 0%, #876EFF 100%);
  background-repeat: repeat-x; }
  .rainbow-hero-8 .hero-content {
    min-height: 800px;
    background: url("/b6964aae704d2bb1cba920344dc004375a145e65/42d09/images/heroes-img/r.svg") no-repeat center center; }
  .rainbow-hero-8 h1 {
    font-size: 6rem;
    font-weight: 700; }
    @media (max-width: 786px) {
      .rainbow-hero-8 h1 {
        font-size: 3rem; } }
  .rainbow-hero-8 p {
    font-weight: 200; }

.rainbow-hero-9 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25); }
  .rainbow-hero-9 .hero-content {
    min-height: 900px;
    background: url("/images/heroes-img/d8569119106fd3c4b3f7fac3c3cb664f607607f0/hero-9.jpg") no-repeat;
    background-size: cover;
    color: #383C41;
    background-position: center center; }
  .rainbow-hero-9 h1 {
    font-size: 72px;
    font-weight: 700; }
  .rainbow-hero-9 h6 {
    color: #47AAFF;
    font-weight: 600; }

.rainbow-hero-10 {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  position: relative; }
  .rainbow-hero-10 .hero-content {
    min-height: 800px;
    overflow: hidden;
    color: #383C41;
    position: relative;
    z-index: 667; }
    .rainbow-hero-10 .hero-content:before, .rainbow-hero-10 .hero-content:after {
      content: "";
      display: block;
      position: absolute;
      background: url("/cfe2e50c43bdd66c5ad707ad4825769534eb982e/c4428/images/heroes-img/hero-spots.svg") no-repeat 0 85%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; }
    .rainbow-hero-10 .hero-content:after {
      transform: scaleX(-1); }

.circle-icon-container {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 115px; }

.skill-btn {
  background-color: #e4e7ec;
  font-size: 12px;
  font-weight: 500;
  color: #6b717b;
  border-radius: 30px;
  padding: 2px 10px;
  margin: 6px; }
  .skill-btn:hover {
    background-color: #c6ccd7;
    color: #fff;
    text-decoration: none; }

.iphone-top {
  top: 115px; }
  @media (max-width: 786px) {
    .iphone-top {
      top: 0px; } }

.remove-top-radius {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.yellow-btn {
  background-color: #ffc025;
  color: #fff; }
  .yellow-btn:hover {
    background-color: #f1ab00;
    color: #fff; }

.bg-1 {
  background-image: linear-gradient(-90deg, #4641ff 0%, #876eff 100%); }

.bg-2 {
  background-image: linear-gradient(90deg, #FFA466 0%, #FF5360 100%);
  position: relative;
  z-index: 4; }

.bg-3 {
  background-image: linear-gradient(90deg, #538DFF 0%, #31DAFF 100%); }

.bg-4 {
  background: url("/images/forms-img/953681e2f14b0888c9c29b97a30012a1d7b86f33/form-2.png") no-repeat;
  background-size: cover; }

.subscribe-input {
  position: relative; }

.scalable-header {
  font-size: 9vw;
  letter-spacing: 2rem;
  opacity: 0.1;
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 5%;
  overflow: hidden; }
  @media (max-width: 786px) {
    .scalable-header {
      letter-spacing: 1rem; } }

.scalable-header2 {
  font-size: 9vw;
  letter-spacing: 2rem;
  opacity: 0.1;
  z-index: 0;
  position: absolute;
  width: 100%;
  top: 15%;
  overflow: hidden; }
  @media (max-width: 786px) {
    .scalable-header2 {
      letter-spacing: 1rem; } }

@media (max-width: 786px) {
  .responsive-input-btn {
    top: 170% !important;
    transform: translate(-50%, -50%);
    width: 200px;
    left: 50%; } }

.z-index4 {
  z-index: 4; }

.z-index0 {
  z-index: 0; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.play-btn {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 40%;
  width: 110px;
  height: 110px; }
  @media (max-width: 786px) {
    .play-btn {
      top: 50%;
      position: relative;
      margin-top: 3rem;
      margin-bottom: 3rem; } }

.width100 {
  width: 100%; }

.width50 {
  width: 50%; }

.radius30 {
  border-radius: 30px; }

.radius5 {
  border-radius: 5px; }

.width60 {
  width: 60%;
  text-align: center; }
  @media (max-width: 786px) {
    .width60 {
      width: 100%; } }

.width70 {
  width: 70%; }
  @media (max-width: 786px) {
    .width70 {
      width: 100%; } }

.width90 {
  width: 90%; }

.width250 {
  width: 250px; }

.width240 {
  width: 240px;
  max-width: 240px; }

.width200 {
  width: 200px; }
  @media (max-width: 786px) {
    .width200 {
      display: none; } }

.top90 {
  top: 90px; }

.top40 {
  top: -40; }

.bg-5 {
  background: url("/images/forms-img/c782daa8cfcc65f4db69bf1580f0fdbc9c8274eb/form-3.png") no-repeat;
  background-size: cover; }

.bg-6 {
  background-image: linear-gradient(#4598fe 0%, #41c0ff 100%); }

.bg-7 {
  background-image: linear-gradient(90deg, #31DAFF 0%, #538DFF 100%); }

.bg-8 {
  background: url("/images/heroes-img/902db8f93973e2de4426f3509ac268f870b17ae7/hero-10.png") no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh; }
  @media (max-width: 786px) {
    .bg-8 {
      height: 700px; } }

.image-top {
  width: 102%;
  margin-left: -2px; }

.border-gray {
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 1rem; }

.border-gray2 {
  border-bottom: 1px solid #e4e7ec;
  padding-bottom: 1rem; }

.border-gray40 {
  border-bottom: 1px solid #e4e7ec;
  width: 40%; }

.box-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4); }

blockquote {
  border: none;
  margin-bottom: -30px;
  position: relative;
  margin: 0 auto;
  padding-left: 0.4em;
  font-style: italic; }
  blockquote:before {
    content: "\201D";
    font-weight: bold;
    font-size: 9vw;
    position: absolute;
    top: -4rem;
    left: -7.5rem;
    font-family: 'Georgia';
    color: #f4f5f7; }

.down-arrow-0 {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0; }

.h-700 {
  height: 700px;
  position: relative; }

.width-260 {
  max-width: 260px; }

.card-shadow {
  box-shadow: 0px 4px 12px 0px rgba(1, 1, 1, 0.1); }

.border-bottom-primary:after {
  border-bottom: 4px solid #5c80ff;
  display: block;
  width: 100px;
  content: "";
  top: 16px;
  position: relative; }

@media (max-width: 786px) {
  .border-bottom-primary {
    text-align: left !important; } }

.border-bottom-white:after {
  border-bottom: 4px solid #fff;
  display: block;
  width: 100px;
  content: "";
  top: 16px;
  position: relative; }

@media (max-width: 786px) {
  .border-bottom-white {
    text-align: left !important; } }

.border-top-white:before {
  border-top: 4px solid #fff;
  display: block;
  width: 100px;
  content: "";
  top: -8px;
  position: absolute; }

@media (max-width: 786px) {
  .border-top-white {
    text-align: left !important;
    top: 8px; } }

@media (max-width: 786px) {
  .mobile-no {
    display: none; } }

.border-right-gray:before {
  border-right: 1px solid #e4e7ec;
  display: block;
  height: 64px;
  content: "";
  top: 0px;
  position: absolute; }
  @media (max-width: 786px) {
    .border-right-gray:before {
      display: none; } }

.fluid-brand {
  display: flex;
  width: 20%;
  height: 64px;
  align-items: center;
  justify-content: center; }

.w-215 {
  width: 215px; }

.border-button {
  border: 1px solid;
  padding: 3px 5px; }

.header-text {
  font-weight: bold;
  color: #fff;
  font-size: 10rem; }
  @media (max-width: 786px) {
    .header-text {
      font-size: 4rem; } }

.main-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 800px; }
  @media (max-width: 786px) {
    .main-header {
      height: 400px; } }

.top20 {
  top: 20%; }

.nav-style {
  flex-direction: row; }
  @media (max-width: 786px) {
    .nav-style {
      flex-direction: column-reverse; } }

.bg-none {
  background-color: #343a40;
  border: none !important; }
  @media (max-width: 786px) {
    .bg-none {
      background-color: transparent !important; } }

.word-wrap {
  word-wrap: break-word; }

@media (max-width: 786px) {
  .sm-column {
    flex-direction: column; } }

@media (max-width: 786px) {
  .sm-center {
    align-items: center !important; } }

.flex-basis {
  flex-basis: 50%; }
  @media (max-width: 786px) {
    .flex-basis {
      flex-basis: 100%; } }

.style-row {
  list-style: none;
  flex-direction: row;
  display: flex;
  margin: 0; }
  @media (max-width: 786px) {
    .style-row {
      flex-direction: column; } }

.border-bottom-gray {
  border-bottom: 1px solid #e4e7ec; }

.border-top-gray {
  border-top: 1px solid #e4e7ec; }

.w-50 {
  width: 50%; }
  @media (max-width: 786px) {
    .w-50 {
      width: 100%; } }

.confetti-position {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  top: 23rem;
  width: 100%;
  left: 0rem; }

.bg-model {
  background-color: linear-gradient(90deg, #538DFF 0%, #31DAFF 100%);
  background: url("/images/heroes-img/1bd87a9ee4ee31910029f44c52a5677219f97466/model.png") no-repeat;
  background-size: contain;
  background-position: center center;
  z-index: 4;
  position: relative;
  height: 100vh; }

.overflow-hidden {
  overflow: hidden; }

.model2-text {
  top: 16rem; }
  @media (max-width: 786px) {
    .model2-text {
      top: 12rem; } }

.bottom-shade2 {
  position: absolute;
  background-image: -webkit-gradient(linear, left top, right top, from(#39FAFF), to(#49A5FF));
  background-image: -webkit-linear-gradient(left, #39FAFF 0%, #49A5FF 100%);
  background-image: -o-linear-gradient(left, #39FAFF 0%, #49A5FF 100%);
  background-image: linear-gradient(to right, #39FAFF 0%, #49A5FF 100%);
  background-repeat: repeat-x;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 180px; }

@media (max-width: 990px) {
  .width-brands {
    width: 100%; } }

.text-left {
  left: -150px; }
  @media (max-width: 1100px) {
    .text-left {
      left: -10px; } }

.arrivals__button {
  border-radius: 25px !important;
  padding: 10px 40px !important;
  background-image: linear-gradient(90deg, #336DFF 0%, #7963FF 100%);
  color: #fff;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 14px; }

.arrivals__products-tshirt:hover {
  background: #fff;
  box-shadow: 0px 0px 61px -2px #e4e4e4; }
  .arrivals__products-tshirt:hover img {
    opacity: 0.3; }

.arrivals__products .description {
  font-weight: 600;
  font-size: 14px;
  padding: 0.5 rem;
  position: absolute;
  text-align: center;
  display: block;
  width: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20%;
  color: #383c41; }
  @media (max-width: 990px) {
    .arrivals__products .description {
      width: 40%; } }

.arrivals__products .price {
  padding-bottom: 20px; }

.arrivals__products p {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #383c41; }

.arrivals__products img {
  height: 215px;
  width: 160px; }

.arrivals__products-blue {
  color: #47aaff; }

.arrivals__products-grey {
  color: #a5adba; }

.arrivals__products-pink {
  color: #ff5fa4; }

.arrivals__products-orange {
  color: #ffa92f; }

.arrivals__products-price {
  color: #47aaff;
  font-size: 2rem; }

.arrivals__products-curency {
  color: #47aaff;
  font-size: 1rem; }

.arrivals__products .button-container {
  position: absolute;
  display: block;
  width: 60%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 56%; }

.arrivals__products .on-hover {
  display: none; }

.arrivals__products .show-text:hover .on-hover {
  display: block; }

.form-control-rounded {
  border-radius: 30px; }

.form-control-hero {
  padding: 20px 40px;
  font-size: 16px;
  line-height: 1;
  border-radius: 40px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2); }
  .form-control-hero::placeholder {
    color: #A5ADBB; }

.stacked-btn-field {
  position: relative; }
  .stacked-btn-field .btn {
    position: absolute;
    right: 4px;
    top: 4px; }

.rainbow-form .form-top {
  z-index: 10; }

.rainbow-form-1 {
  background: #fff; }
  .rainbow-form-1 .blue-shade {
    background: url("/images/forms-img/21f9933d308846efa3d7b921b3dd01b6553a0223/form-1.png") #fff no-repeat center center;
    position: absolute;
    height: 420px;
    width: 100%; }
  .rainbow-form-1 .form-top {
    margin-top: 109px; }
    .rainbow-form-1 .form-top h2 {
      color: #fff; }
    .rainbow-form-1 .form-top p {
      color: #fff; }
  .rainbow-form-1 .card {
    margin-top: 88px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 0; }
    .rainbow-form-1 .card .card-block {
      padding: 4.44rem 5rem 5rem; }
    .rainbow-form-1 .card h5 {
      margin-bottom: 44px; }

.right0 {
  right: 0; }

.bg-9 {
  background: url("/f2c156e88e2cabfcbed60d3b50ab0ca48d239029/8dac5/images/forms-img/sneakers.png") no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh; }

.border-none {
  border: none; }

.or-text {
  top: 475px;
  position: absolute;
  left: 0;
  right: 0;
  width: 50px;
  margin: 0 auto; }
  @media (max-width: 575px) {
    .or-text {
      top: 500px; } }

.btn-width1 {
  width: 160px; }

.btn-width2 {
  width: 240px; }

.radius-0 {
  border-radius: 0; }

.border-gradient {
  height: 6px;
  background-image: linear-gradient(90deg, #31DAFF 0%, #538DFF 100%); }

.border-gradient2 {
  height: 6px;
  background-image: linear-gradient(90deg, #336DFF 0%, #7963FF 100%); }

.border-gradient3 {
  height: 6px;
  background-image: linear-gradient(90deg, #FFA466 0%, #FF5360 100%); }

.btn-f {
  background: url("/90d71fb8791f54df9d71ec69fd2ff110462c2776/bf2ef/images/icons/half-facebook.svg") no-repeat, linear-gradient(90deg, #3e70d8 0%, #16419b 100%);
  cursor: pointer; }

.btn-g {
  background: url("/2cb5910c351b00534898b5dd1c817a9fc55088d8/7e0d0/images/icons/half-gplus.svg") no-repeat, linear-gradient(90deg, #ff6b43 0%, #ff5e4b 100%);
  cursor: pointer; }

.border-bottom-blue {
  border-bottom: 2px solid #47aaff; }

.border-blue {
  border: 2px solid #47aaff; }

.border-bottom-red {
  border-bottom: 2px solid #ff6565; }

.border-red {
  border: 2px solid #ff6565; }

.border-top-red {
  border-top: 2px solid #ff6565; }

.border-bottom-green {
  border-bottom: 2px solid #18d989; }

.border-green {
  border: 2px solid #18d989; }




.rainbow-hero-4 {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}



 .copy a:before {
    background: #E7E8E9;
    
    content: '';
    display: block;
    bottom: 6px;
    left: 0px;
    height: 12px;
    width: 100%;
    z-index: -1
}


h1, h2, h3, h4, h5, h6, .navbar-brand {
  
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
}

p {
  font-family: 'Nunito', sans-serif;
}

.roboto {
  font-family: 'Nunito', sans-serif!important;
}



.published:hover {
	-webkit-transform: translate(0,-0.1em) scale(1.005);
    -moz-transform: translate(0,-0.1em) scale(1.005);
    -o-transform: translate(0,-0.1em) scale(1.005);
    -ms-transform: translate(0,-0.1em) scale(1.005);
    transform: translate(0,-0.1em) scale(1.005);

    box-shadow: 0px 6px 16px 0px rgba(1, 1, 1, 0.2);
    
  

}


.published {
	box-shadow: 0px 4px 12px 0px rgba(1, 1, 1, 0.1);
	text-decoration: none!important;
	transition-duration: 0.3s;

}

a:hover{
	text-decoration:none;

}


.zoom-image:hover {
    transform: scale(1.03);
    transition-duration: 0.3s;
    cursor: zoom-in;
  }

 


.zoom-image {
	box-shadow: 0px 0px 0px 0px rgba(1, 1, 1, 0.1);
	text-decoration: none!important;
	transition-duration: 0.3s;

}


.multi-pulse {
	
    animation-name: multi_pulse_animation, color-change;
    animation-duration: 3000ms;
    transform-origin:40% 0%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.multi-pulse:hover {
    animation: none!important;
}


@keyframes multi_pulse_animation {
    0% { transform: scale(1); }
    
    
    50% { transform: scale(1.02); }
    
    100% { transform: scale(1); }
}




.not-published {
	box-shadow: 0px 4px 12px 0px rgba(1, 1, 1, 0.1);
	text-decoration: none!important;
	opacity: 0.4!important;
	cursor: pointer!important;

}




@-webkit-keyframes color-change {
    0% { color: #383C41; }
    50% { color: #FF5360; }
    100% { color: #383C41; }
}
@-moz-keyframes color-change {
    0% { color: #383C41; }
    50% { color: #FF5360; }
    100% { color: #383C41; }
}
@-ms-keyframes color-change {
    0% { color: #383C41; }
    50% { color: #FF5360; }
    100% { color: #383C41; }
}
@-o-keyframes color-change {
    0% { color: #383C41; }
    50% { color: #FF5360; }
    100% { color: #383C41; }
}
@keyframes color-change {
    0% { color: #383C41; }
    50% { color: #FF5360; }
    100% { color: #383C41; }
}



.modal-content {
	background-color: #f7f7f9!important;
	border: none!important;
}

.modal-header {
	border-bottom: none!important;
}

.modal-footer {
	border-top: none!important;
}

.modal-body {
	background-color: #fff!important;
}





